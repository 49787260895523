<template>
	<div class="grid">
		<div v-for="(item, i) in role_dashboard" :key="i" class="col-12">
			<Card>
                <template #title>
                    
                </template>
                <template #content>
					<div>
						<div v-html="item.content"></div>
					</div>
				</template>
			</Card>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			title: 'Dashboard',
			api: '/api/dashboard',
			role_dashboard: [],
		}
	},
	productService: null,
	eventService: null,
	created() {
		this.getRoleDashboard();		
	},
	mounted() {
		this.$e.emit('updateTitle', this.title);
	},
	methods: {
		getRoleDashboard(){
			this.$Progress.start()
			this.$httpAuth.get(this.api + '/get/role-dashboard')
            .then((response) => {
				this.$Progress.finish()
                this.role_dashboard = response.data;
            })
            .catch((error) => {
				this.$Progress.fail()
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		}
	}
}
</script>

<style lang="scss" scoped>
	
</style>
